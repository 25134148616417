import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import background3 from "../../img/background/back-3.svg";
import quote_2 from "../../img/quotes/quote2.svg";
import supabase from "../../utils/supabaseClient";

const Category = () => {
  const [industries, setIndustries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getIndustries();
  }, []);

  async function getIndustries() {
    try {
      const { data, error } = await supabase.from("industries").select();
      if (error) throw error;
      setIndustries(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Element name="deadline" className="mt-12">
      <h2 className="text-4xl my-12 text-center md:text-left">
        <span className="text-blue">Категории </span>поданных заявок
      </h2>
      <img src={background3} alt="back3" className="absolute" />
      <div className="p-4 max-w-[583px] mx-auto relative">
        <div className="flex justify-between mb-4">
          <div className="text-xl text-blue font-bold">Всего заявок</div>
          <div className="font-bold">
            {industries.length > 0
              ? industries.reduce((acc, industry) => acc + industry.total, 0)
              : 0}
          </div>
        </div>
        <div className="overflow-hidden">
          {industries.map((industry) => (
            <div
              key={industry.id}
              className="flex flex-col md:flex-row items-center my-12"
            >
              <div className="w-full md:w-32 font-bold text-center md:text-left">
                {industry.name}
              </div>
              <div className="relative flex-grow bg-gray-200 h-2.5 rounded-full md:ml-4 w-full md:w-auto mt-2 md:mt-0">
                <div
                  className="absolute top-0 left-0 h-full bg-blue rounded-full"
                  style={{
                    width: `${(industry.completed / industry.total) * 100}%`,
                  }}
                ></div>
              </div>
              <div className="flex flex-row text-center md:text-left mt-4 md:mt-0 md:ml-8">
                {industry.completed}/{industry.total}{" "}
                {Math.round((industry.completed / industry.total) * 100)}%
                <div className="ml-8"> {industry.completed}</div>
              </div>
              {/* <div className="ml-8 flex flex-end items-center mt-4 md:mt-0">
                    <div className="flex items-center mr-2">{industry.completed}</div>
              </div> */}
            </div>
          ))}
        </div>
        <div className="mt-7 flex justify-end">
          <img src={quote_2} alt="Quotes" />
        </div>
      </div>
    </Element>
  );
};

export default Category;
