import { useState } from "react";
import supabase from "../utils/supabaseClient";

const useFileUpload = (enqueueSnackbar) => {
  const [attachedFile, setAttachedFile] = useState(null);
  const [additionalFile, setAdditionalFile] = useState(null);

  const generateUniqueIdentifier = (fileName) => {
    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2, 15);
    return `${timestamp}-${randomString}-${fileName}`;
  };

  const handleFileChange = (file) => {
    if (!file || file.type !== "application/pdf") {
      enqueueSnackbar("Пожалуйста, загрузите PDF-файл", { variant: "error" });
      return;
    }

    const fileName = file.name;
    const isValidFileName = /^[a-zA-Z0-9-_\.]+$/.test(fileName);

    if (!isValidFileName) {
      enqueueSnackbar("Имя файла должно содержать только латинские символы", {
        variant: "error",
      });
      return;
    }

    setAttachedFile(file);
  };

  const handleAdditionalFileChange = (file) => {
    if (!file || file.type !== "application/pdf") {
      enqueueSnackbar("Пожалуйста, загрузите PDF-файл", { variant: "error" });
      return;
    }

    const fileName = file.name;
    const isValidFileName = /^[a-zA-Z0-9-_\.]+$/.test(fileName);

    if (!isValidFileName) {
      enqueueSnackbar("Имя файла должно содержать только латинские символы", {
        variant: "error",
      });
      return;
    }

    setAdditionalFile(file);
  };

  const uploadFile = async (file, bucketName) => {
    if (!file) return "";

    const uniqueFileName = generateUniqueIdentifier(file.name);
    const filePath = `public/uploads/${uniqueFileName}`;
    const { data, error } = await supabase.storage
      .from(bucketName)
      .upload(filePath, file);

    if (error) {
      console.error("Error uploading file:", error.message);
      enqueueSnackbar("Ошибка загрузки файла", { variant: "error" });
      return "";
    }

    enqueueSnackbar("Файл успешно загружен", { variant: "success" });
    return uniqueFileName;
  };

  return {
    attachedFile,
    additionalFile,
    handleFileChange,
    handleAdditionalFileChange,
    uploadFile,
  };
};

export default useFileUpload;
