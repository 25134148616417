import React, { useState, useEffect } from "react";
import arrow from "../../img/icons/Vector1.png";
import supabase from "../../utils/supabaseClient";

import { useMask } from "@react-input/mask";

const FirstForm = ({ formData, handleChange, nextStep }) => {
  const [industries, setIndustries] = useState([]);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [formValid, setFormValid] = useState(false);

  // BETTER MASKING LIB
  const inputRef = useMask({ mask: '+7 (___) ___-__-__', replacement: { _: /\d/ } });

  useEffect(() => {
    getIndustries();
  }, []);

  useEffect(() => {
    setFormValid(
      formData.fullName &&
        formData.projectName &&
        formData.phone &&
        formData.industry &&
        formData.email &&
        !emailError &&
        !phoneError
    );
  }, [formData, emailError, phoneError]);


  async function getIndustries() {
    const { data } = await supabase.from("industries").select();
    setIndustries(data);
  }

  const validateEmail = (email) => {
    const emailPattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!emailPattern.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError(null);
    }
  };

  const validatePhone = (phone) => {
    const phonePattern = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
    if (!phonePattern.test(phone)) {
      setPhoneError("Please enter a valid phone number");
    } else {
      setPhoneError(null);
    }
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    handleChange(event);
    validateEmail(value);
  };

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    handleChange(event);
    validatePhone(value);
  };

  return (
    <div className="mt-12 my-12">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="col-span-1">
          <label
            htmlFor="fullName"
            className="block text-base font-medium text-gray-700"
          >
            Полное имя<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Введите имя"
            className="mt-1 p-2 w-full border rounded-md text-blue font-bold "
          />
        </div>

        <div className="col-span-1">
          <label
            htmlFor="projectName"
            className="block text-base font-medium text-gray-700"
          >
            Название проекта<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="projectName"
            name="projectName"
            value={formData.projectName}
            onChange={handleChange}
            placeholder="Введите название проекта"
            className="mt-1 p-2 w-full border rounded-md text-blue font-bold"
          />
        </div>

        <div className="col-span-1">
          <label
            htmlFor="phoneNumber"
            className="block text-base font-medium text-gray-700"
          >
            Телефон<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            ref={inputRef}
            value={formData.phone}
            onChange={handlePhoneChange}
            placeholder="+7 (___) ___-__-__"
            className="mt-1 p-2 w-full border rounded-md text-blue font-bold"
          />
          {phoneError && <p className="text-red-500">{phoneError}</p>}
        </div>

        <div className="col-span-1">
          <label
            htmlFor="industry"
            className="block text-base font-medium text-gray-700 w-[283px] h-[32px]"
          >
            Отрасль<span className="text-red-500">*</span>
          </label>
          <select
            id="industry"
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
          >
            <option value=""> Выберите отрасль</option>
            {industries.map((industry) => (
              <option key={industry.id} value={industry.id}>
                {industry.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-span-1">
          <label
            htmlFor="email"
            className="block text-base font-medium text-gray-700"
          >
            e-mail<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleEmailChange}
            placeholder="Введите e-mail"
            className="mt-1 p-2 w-full border rounded-md text-blue font-bold"
          />
          {emailError && <p className="text-red-500">{emailError}</p>}
        </div>
      </div>
      <div className="border-b border-solid border-opacity-34 dark:border-opacity-60 mt-6"></div>
      <div className="flex justify-between mt-10">
        <h1>Перейти к следующему шагу</h1>
        <button
          onClick={nextStep}
          disabled={!formValid}
          className={`p-2 rounded-md w-full text-white flex items-center justify-center ${
            formValid ? "bg-blue" : "bg-gray-400"
          }`}
          style={{
            width: "283px",
            height: "32px",
            borderRadius: "8px",
            cursor: formValid ? "pointer" : "not-allowed",
          }}
        >
          Далее
          <img src={arrow} alt="arrow" className="ml-2" />
        </button>
      </div>
    </div>
  );
};

export default FirstForm;
